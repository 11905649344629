<template>
  <div
    class="`
      flex flex-row border flex-grow-0 flex-shrink-0 border-contrast
      rounded-lg w-full bg-backfill my-1
      rewards-redeem-component
    `"
    :class="{'cursor-pointer': isRedeemable}"
    @click="click"
  >

    <div class="flex flex-col items-start justify-around w-full px-4 py-3">
      <p
        class="font-bold "
        :class="isRedeemable ? 'text-secondary' : 'text-content opacity-80' "
      >
        {{rewardToRedeem.title}}
      </p>
      <div class="flex items-center justify-between w-full py-2">
        <p class="py-1 text-xs text-content">{{description}}</p>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'RewardsRedeemComponent',
  props: {
    rewardToRedeem: {
      type: Object,
      required: true,
    },
    isRedeemable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    description() {
      if (this.isRedeemable) {
        return `Redeem for ${this.rewardToRedeem.pointsRequired} points`;
      }
      return `Requires ${this.rewardToRedeem.pointsRequired} points`;
    },
  },
  methods: {
    click() {
      if (this.isRedeemable) {
        this.$emit('redeem', this.rewardToRedeem);
      } else {
        this.$emit('notEnoughPointsToRedeem');
      }
    },
  },
};
</script>
