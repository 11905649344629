<template>
    <interior-page-content-container
      nav-selection="rewards"
      @back-click="backNav"
      :show-bottom-nav="false"
      :show-back-button-top-left="true"
      :show-back-button-in-nav="false"
      :modal-show="modal.requestReward || modal.showCode || modal.alert"
      :loading="loading"
      :notificationMessage.sync="notificationMessage"
    >

      <template #modal>
        <alert-modal
          v-if="modal.alert"
          description="You do not have enough points to redeem this reward"
          title="More points needed"
          @back="modal.alert = false"
        />
        <rewards-redeem-modal
          v-if="modal.requestReward"
          :rewardToRedeem="rewardToRedeem"
          @redeem="redeemReward"
          @cancel="modal.requestReward = false"
        />
        <rewards-redeem-complete-modal
          v-if="modal.showCode"
          :code="code"
          @code-copied="codeCopied"
          @back="modal.showCode = false"
        />
      </template>

      <template #title>
        Rewards
      </template>

      <template #content>
        <div class="`
          relative
          flex flex-col items-stretch self-center h-full pt-10
          w-5/6 phone:w-3/4 md:w-2/3 lg:w-3/5 max-w-md
          mb-10 pb-10
        `">
          <div class="``
            absolute w-full top-0 left-0 right-0 flex flex-row justify-center mx-auto
          ">
            <div
              class="`
                flex-grow-0 flex flex-col flex-shrink-0 pt-2 w-full bg-background
              `"
            >
              <p class="mb-2 text-xl font-semibold opacity-70 text-content">
                Your Balance: {{points}} pts
              </p>

              <tab-input
                left-tab="Redeem"
                right-tab="Earn Points"
                :selected="selected"
                @update:selected="tabClick"
              />

            </div>

          </div>

          <div class="h-full pb-8 mt-8">
            <!-- Redeem -->
            <div
              v-if="selected === 0"
              class="flex flex-col w-full min-h-full mt-4 redeem"
            >
              <div
                class="flex flex-col justify-center flex-grow h-full text-lg font-bold"
                v-if="rewardsToRedeem.length === 0"
              >
                <p class="text-content">
                  There are no rewards <br> available at this time
                </p>

              </div>
              <rewards-redeem-component
                v-for="rewardToRedeem in rewardsToRedeem"
                :key="rewardToRedeem.name"
                @redeem="redeemRewardHandler"
                @notEnoughPointsToRedeem="modal.alert = true"
                :reward-to-redeem="rewardToRedeem"
                :isRedeemable="rewardToRedeem.pointsRequired <= points"
              />
            </div>

            <!-- Points -->
            <div
              v-else-if="selected === 2"
              class="w-full mt-7 points"
            >
              <rewards-points-component
                v-for="reward in availableRewards" :key="reward.name"
                :reward="reward"
              />
            </div>

          </div>

        </div>
      </template>

    </interior-page-content-container>
</template>

<script>
import { USER_GET, USER_GET_AVAILABLE_REWARDS, USER_REDEEM_REWARD } from '@/store/actions';
import { USER_POINTS } from '@/store/getters';

import InteriorPageContentContainer from '@/components/shared/containers/InteriorPageContentContainer/InteriorPageContentContainer.vue';
import TabInput from '@/components/shared/inputs/TabInput.vue';
import RewardsRedeemComponent from '@/components/rewards/RewardsRedeemComponent.vue';
import RewardsPointsComponent from '@/components/rewards/RewardsPointsComponent.vue';
import RewardsRedeemModal from '@/components/rewards/RewardsRedeemModal.vue';
import RewardsRedeemCompleteModal from '@/components/rewards/RewardsRedeemCompleteModal.vue';
import AlertModal from '../components/shared/inputs/AlertModal.vue';

export default {
  name: 'Rewards',
  components: {
    InteriorPageContentContainer,
    TabInput,
    RewardsRedeemComponent,
    RewardsPointsComponent,
    RewardsRedeemModal,
    RewardsRedeemCompleteModal,
    AlertModal,
  },
  data() {
    return {
      loading: true,
      selected: 0,
      availableRewards: [],
      code: '',
      notificationMessage: '',
      rewardsToRedeem: [
        {
          title: '5% off store wide discount',
          percentOff: 5,
          pointsRequired: 10000,
        },
        {
          title: '10% off store wide discount',
          pointsRequired: 20000,
          percentOff: 10,
        },
      ],
      rewardToRedeem: {},
      modal: {
        requestReward: false,
        showCode: false,
        alert: false,
      },
    };
  },
  computed: {
    points() {
      return this.$store.getters[USER_POINTS];
    },
  },
  async created() {
    const { tab } = this.$route.params;
    if (tab && this.$route.params.tab.toLowerCase() === 'redeem') this.selected = 0;
    else if (tab && this.$route.params.tab.toLowerCase() === 'points') this.selected = 2;
    else {
      this.$router.push({
        name: 'Rewards',
        params: { tab: 'redeem' },
      });
    }
    await this.$store.dispatch(USER_GET);
    this.$store.dispatch(USER_GET_AVAILABLE_REWARDS)
      .then((rewards) => {
        this.availableRewards = rewards;
        this.loading = false;
      });
  },
  methods: {
    backNav() {
      this.$router.push('Dashboard');
    },
    tabClick(newVal) {
      this.selected = newVal;

      let newTab = '';
      if (newVal === 0) newTab = 'Redeem';
      if (newVal === 2) newTab = 'Points';

      this.$router.push({
        name: 'Rewards',
        params: {
          tab: newTab,
        },
      });
    },
    redeemRewardHandler(rewardToRedeem) {
      this.rewardToRedeem = rewardToRedeem;
      this.modal.requestReward = true;
    },
    async redeemReward() {
      this.modal.requestReward = false;
      this.loading = true;
      this.code = await this.$store.dispatch(USER_REDEEM_REWARD, {
        pointsRequired: this.rewardToRedeem.pointsRequired,
        percentOff: this.rewardToRedeem.percentOff,
      });
      this.loading = false;
      this.modal.showCode = true;
    },
    codeCopied() {
      this.modal.showCode = false;
      this.notificationMessage = 'Code Copied!';
    },
  },
};
</script>

<style>

</style>
