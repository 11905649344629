<template>
  <div
    @click="$emit('click')"
    class="`
      flex flex-col border flex-grow-0 flex-shrink-0 border-contrast
      rounded-lg w-full bg-backfill h-28
      rewards-points-component px-2 py-4 mb-2
    `"
  >
    <div class="flex flex-row flex-grow">
      <div class="flex flex-col items-start flex-grow text-left flex-between">
          <p class="font-bold text-primary">{{reward.name}}</p>
          <p class="py-1 text-sm font-semibold text-content">{{ reward.description }}</p>
      </div>

      <div class="`
        flex flex-col justify-center min-w-min h-full
        text-2xl pl-4
        font-extrabold rounded-r-lg text-secondary
      `">
        <p class="whitespace-nowrap">{{reward.value}} pts</p>

      </div>
    </div>

    <progress-bar
      v-if="reward.limit < 100000"
      :percentage="precentage"
    />

  </div>
</template>

<script>
import ProgressBar from '@/components/shared/general/ProgressBar.vue';

export default {
  name: 'RewardsRedeemComponent',
  components: {
    ProgressBar,
  },
  props: {
    reward: {
      type: Object,
      required: true,
    },
  },
  computed: {
    precentage() {
      return ((this.reward.count * this.reward.value) / this.reward.limit) * 100;
    },
  },
};
</script>
