<template>
  <modal-container
    @cancel="$emit('back')"
    class="alert-modal"
  >
    <p class="text-xl font-bold text-left text-primary">{{title}}</p>
    <p class="my-10 font-bold text-left">{{description}}</p>
    <div class="flex flex-row justify-center w-full sm:justify-end">

      <button-large
        @click="$emit('back')"
        :color="buttonColor"
        class="w-32 ml-5"
      >
        {{buttonText}}
      </button-large>

    </div>
  </modal-container>
</template>

<script>
import ModalContainer from '@/components/shared/containers/ModalContainer.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'AlertModal',
  components: {
    ButtonLarge,
    ModalContainer,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Back',
    },
    buttonColor: {
      type: String,
      required: false,
      default: 'secondary',
      validate: (val) => ['green', 'red', 'primary', 'secondary', 'tertiary'].inlcudes(val),
    },

  },
};
</script>

<style>

</style>
