<template>
  <div class="flex flex-shrink-0 w-full h-2 overflow-hidden rounded-md bg-contrast">
    <div
      class='`
        w-full transition-all duration-700 ease-in-out
       bg-green-600 rounded-md progress-bar
      `'
      :style=" `max-width: ${percentage}%`"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ProgresBar',
  props: {
    percentage: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style>

</style>
