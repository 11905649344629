<template>
  <modal-container
    @cancel="$emit('back')"
    class="rewards-redeem-complete-modal"
  >
    <p class="text-xl font-bold text-left">
      Congratulations!
    </p>
    <p class="my-10">
      Your coupon code is below. Click the "Copy" button to copy it
    </p>
    <p :class="{'text-green-500': copied}" class="flex mb-10">
      {{code}}
      <checkmark v-if="copied" class="ml-2 copied" />
    </p>
    <div class="flex flex-row justify-end w-full">
      <button-large
        id="copy"
        color="secondary"
        class="mr-3 w-28 phone:w-36"
      >
        Copy
      </button-large>
      <button-large
        @click="$emit('back')"
        color="secondary"
        class="w-28 phone:w-36"
      >
        Back
      </button-large>
    </div>

  </modal-container>
</template>

<script>

import Checkmark from 'vue-material-design-icons/Check.vue';
import ModalContainer from '@/components/shared/containers/ModalContainer.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'RewardsRedeemCompleteModal',
  components: {
    ModalContainer,
    ButtonLarge,
    Checkmark,
  },
  props: {
    rewardToRedeem: {
      type: Object,
      required: false,
    },
    code: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  mounted() {
    document.querySelector('#copy').addEventListener('click', this.copy);
  },
  methods: {
    copy() {
      const el = document.createElement('textarea');
      el.value = this.code;
      el.style.left = '-9999px';
      document.body.appendChild(el);
      const selected = document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      this.copied = true;
    },
  },
};
</script>

<style>

</style>
