<template>
  <modal-container
    @cancel="$emit('cancel')"
    class="rewards-redeem-modal"
  >
    <p class="text-xl font-bold text-left">
      {{ rewardToRedeem.title }}
    </p>
    <p class="my-10">
      Would you like to claim this rewards?
    </p>
    <div class="flex flex-row justify-end w-full">
      <button-large
        @click="$emit('cancel')"
        color="red"
        class="mr-3 w-28 phone:w-36"
      >
        Cancel
      </button-large>
      <button-large
        @click="$emit('redeem')"
        color="green"
        class="w-28 phone:w-36"
      >
        Redeem
      </button-large>
    </div>

  </modal-container>
</template>

<script>
import ModalContainer from '@/components/shared/containers/ModalContainer.vue';
import ButtonLarge from '@/components/shared/Buttons/ButtonLarge.vue';

export default {
  name: 'RewardsRedeemModal',
  components: {
    ModalContainer,
    ButtonLarge,
  },
  props: {
    rewardToRedeem: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style>

</style>
